/* tslint:disable */
/* eslint-disable */
/**
 * Job API
 * API schema for the Job API. This API allows for submitting, monitoring and controlling jobs running in the service.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// URLSearchParams not necessarily used
// @ts-ignore
import { URL, URLSearchParams } from 'url';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { CancelJobRequest } from '../models';
// @ts-ignore
import { CreateJobRequest } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { Job } from '../models';
// @ts-ignore
import { JobOutputsResponse } from '../models';
// @ts-ignore
import { JobsResponse } from '../models';
// @ts-ignore
import { TaskExecutionUpdatesResponse } from '../models';
// @ts-ignore
import { TaskExecutionsResponse } from '../models';
// @ts-ignore
import { TasksResponse } from '../models';
// @ts-ignore
import { UpdatedJobsResponse } from '../models';
/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Cancel the job specified by the `jobId`.
     * @summary Cancel a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CancelJobRequest} cancelJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJob: async (
      queueId: string,
      cancelJobRequest: CancelJobRequest,
      region?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('cancelJob', 'queueId', queueId);
      // verify required parameter 'cancelJobRequest' is not null or undefined
      assertParamExists('cancelJob', 'cancelJobRequest', cancelJobRequest);
      const localVarPath = `/queues/{queueId}/jobs:cancel`.replace(
        `{${'queueId'}}`,
        encodeURIComponent(String(queueId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelJobRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a new job in the queue specified by `queueId`. The description of the job specified in the request body consists of a combination of the generic structure given here and executor-specific parts. In particular the `payload` property contains executor specific data. For the specifics there refer to the executor schema definition. This endpoint expects an OAuth2 bearer token with code:all scope.
     * @summary Create a new job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CreateJobRequest} createJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJob: async (
      queueId: string,
      createJobRequest: CreateJobRequest,
      region?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('createJob', 'queueId', queueId);
      // verify required parameter 'createJobRequest' is not null or undefined
      assertParamExists('createJob', 'createJobRequest', createJobRequest);
      const localVarPath = `/queues/{queueId}/jobs`.replace(
        `{${'queueId'}}`,
        encodeURIComponent(String(queueId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createJobRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the specified job. Job related data is expected to be physically deleted within 30 days of its creation.
     * @summary Delete a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJob: async (
      queueId: string,
      jobId: string,
      region?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('deleteJob', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('deleteJob', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the job specified by the `jobId`.
     * @summary Get a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob: async (
      queueId: string,
      jobId: string,
      region?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getJob', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJob', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the list of logs from a job with the specified job ID.  A signed URL to download a log can be retrieved by using the information provided in the response and the resource API.
     * @summary Get the list of logs from a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobLogs: async (
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getJobLogs', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJobLogs', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}/logs`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the list of outputs of the job with the specified `jobId`. A signed URL to download the data of an output can be retrieved by using the information provided in the response and the resource API.
     * @summary Get a list of the job\'s outputs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobOutputs: async (
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getJobOutputs', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJobOutputs', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}/outputs`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the jobs in a queue. By default retrieves at most 100 jobs per request. Further jobs can be retrieved by calling the `nextUrl` given in the `pagination` property of the response. If no `nextUrl` is present, that means that no more jobs can be retrieved.  **Note:** There may be fewer than 100 jobs retrieved per request, even though there may be more jobs available.
     * @summary Get a list of jobs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobs: async (
      queueId: string,
      region?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getJobs', 'queueId', queueId);
      const localVarPath = `/queues/{queueId}/jobs`.replace(
        `{${'queueId'}}`,
        encodeURIComponent(String(queueId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the task executions in the specified job ID that have been updated since the last call.
     * @summary Get the updated task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskExecutionUpdates: async (
      queueId: string,
      jobId: string,
      region?: string,
      after?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getTaskExecutionUpdates', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getTaskExecutionUpdates', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}/executions:updates`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (after !== undefined) {
        localVarQueryParameter['after'] =
          (after as any) instanceof Date ? (after as any).toISOString() : after;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the task executions in the specified job ID.
     * @summary Get the task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskExecutions: async (
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getTaskExecutions', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getTaskExecutions', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}/executions`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (filterName !== undefined) {
        localVarQueryParameter['filter[name]'] = filterName;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all tasks of the specified `jobId`. To retrieve a specific task by name, add a filter as a query param using the syntax filter[name]=<name to find>.
     * @summary Get the list of tasks in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTasks: async (
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getTasks', 'queueId', queueId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getTasks', 'jobId', jobId);
      const localVarPath = `/queues/{queueId}/jobs/{jobId}/tasks`
        .replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (filterName !== undefined) {
        localVarQueryParameter['filter[name]'] = filterName;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the jobs in the specified queue that have been updated since the last call. Use the special value @default for the queue ID to target the default queue.
     * @summary Get the updated jobs in a queue
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [before] A timestamp in ISO 8601 format used to indicate that only results before this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdatedJobs: async (
      queueId: string,
      region?: string,
      after?: string,
      before?: string,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('getUpdatedJobs', 'queueId', queueId);
      const localVarPath = `/queues/{queueId}/jobs:updates`.replace(
        `{${'queueId'}}`,
        encodeURIComponent(String(queueId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (after !== undefined) {
        localVarQueryParameter['after'] =
          (after as any) instanceof Date ? (after as any).toISOString() : after;
      }

      if (before !== undefined) {
        localVarQueryParameter['before'] =
          (before as any) instanceof Date
            ? (before as any).toISOString()
            : before;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     * Cancel the job specified by the `jobId`.
     * @summary Cancel a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CancelJobRequest} cancelJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelJob(
      queueId: string,
      cancelJobRequest: CancelJobRequest,
      region?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelJob(
        queueId,
        cancelJobRequest,
        region,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Creates a new job in the queue specified by `queueId`. The description of the job specified in the request body consists of a combination of the generic structure given here and executor-specific parts. In particular the `payload` property contains executor specific data. For the specifics there refer to the executor schema definition. This endpoint expects an OAuth2 bearer token with code:all scope.
     * @summary Create a new job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CreateJobRequest} createJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createJob(
      queueId: string,
      createJobRequest: CreateJobRequest,
      region?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createJob(
        queueId,
        createJobRequest,
        region,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete the specified job. Job related data is expected to be physically deleted within 30 days of its creation.
     * @summary Delete a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteJob(
      queueId: string,
      jobId: string,
      region?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJob(
        queueId,
        jobId,
        region,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the job specified by the `jobId`.
     * @summary Get a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJob(
      queueId: string,
      jobId: string,
      region?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJob(
        queueId,
        jobId,
        region,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the list of logs from a job with the specified job ID.  A signed URL to download a log can be retrieved by using the information provided in the response and the resource API.
     * @summary Get the list of logs from a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobLogs(
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobOutputsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobLogs(
        queueId,
        jobId,
        region,
        path,
        paginationToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the list of outputs of the job with the specified `jobId`. A signed URL to download the data of an output can be retrieved by using the information provided in the response and the resource API.
     * @summary Get a list of the job\'s outputs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobOutputs(
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobOutputsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobOutputs(
        queueId,
        jobId,
        region,
        path,
        paginationToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the jobs in a queue. By default retrieves at most 100 jobs per request. Further jobs can be retrieved by calling the `nextUrl` given in the `pagination` property of the response. If no `nextUrl` is present, that means that no more jobs can be retrieved.  **Note:** There may be fewer than 100 jobs retrieved per request, even though there may be more jobs available.
     * @summary Get a list of jobs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobs(
      queueId: string,
      region?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobs(
        queueId,
        region,
        paginationToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the task executions in the specified job ID that have been updated since the last call.
     * @summary Get the updated task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskExecutionUpdates(
      queueId: string,
      jobId: string,
      region?: string,
      after?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TaskExecutionUpdatesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTaskExecutionUpdates(
          queueId,
          jobId,
          region,
          after,
          paginationToken,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the task executions in the specified job ID.
     * @summary Get the task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskExecutions(
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TaskExecutionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTaskExecutions(
          queueId,
          jobId,
          region,
          filterName,
          paginationToken,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all tasks of the specified `jobId`. To retrieve a specific task by name, add a filter as a query param using the syntax filter[name]=<name to find>.
     * @summary Get the list of tasks in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTasks(
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TasksResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(
        queueId,
        jobId,
        region,
        filterName,
        paginationToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the jobs in the specified queue that have been updated since the last call. Use the special value @default for the queue ID to target the default queue.
     * @summary Get the updated jobs in a queue
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [before] A timestamp in ISO 8601 format used to indicate that only results before this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUpdatedJobs(
      queueId: string,
      region?: string,
      after?: string,
      before?: string,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdatedJobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdatedJobs(
        queueId,
        region,
        after,
        before,
        paginationToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     * Cancel the job specified by the `jobId`.
     * @summary Cancel a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CancelJobRequest} cancelJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJob(
      queueId: string,
      cancelJobRequest: CancelJobRequest,
      region?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .cancelJob(queueId, cancelJobRequest, region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new job in the queue specified by `queueId`. The description of the job specified in the request body consists of a combination of the generic structure given here and executor-specific parts. In particular the `payload` property contains executor specific data. For the specifics there refer to the executor schema definition. This endpoint expects an OAuth2 bearer token with code:all scope.
     * @summary Create a new job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {CreateJobRequest} createJobRequest
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJob(
      queueId: string,
      createJobRequest: CreateJobRequest,
      region?: string,
      options?: any,
    ): AxiosPromise<Job> {
      return localVarFp
        .createJob(queueId, createJobRequest, region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete the specified job. Job related data is expected to be physically deleted within 30 days of its creation.
     * @summary Delete a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJob(
      queueId: string,
      jobId: string,
      region?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteJob(queueId, jobId, region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the job specified by the `jobId`.
     * @summary Get a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(
      queueId: string,
      jobId: string,
      region?: string,
      options?: any,
    ): AxiosPromise<Job> {
      return localVarFp
        .getJob(queueId, jobId, region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of logs from a job with the specified job ID.  A signed URL to download a log can be retrieved by using the information provided in the response and the resource API.
     * @summary Get the list of logs from a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobLogs(
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<JobOutputsResponse> {
      return localVarFp
        .getJobLogs(queueId, jobId, region, path, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of outputs of the job with the specified `jobId`. A signed URL to download the data of an output can be retrieved by using the information provided in the response and the resource API.
     * @summary Get a list of the job\'s outputs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [path] An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobOutputs(
      queueId: string,
      jobId: string,
      region?: string,
      path?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<JobOutputsResponse> {
      return localVarFp
        .getJobOutputs(queueId, jobId, region, path, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the jobs in a queue. By default retrieves at most 100 jobs per request. Further jobs can be retrieved by calling the `nextUrl` given in the `pagination` property of the response. If no `nextUrl` is present, that means that no more jobs can be retrieved.  **Note:** There may be fewer than 100 jobs retrieved per request, even though there may be more jobs available.
     * @summary Get a list of jobs
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobs(
      queueId: string,
      region?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<JobsResponse> {
      return localVarFp
        .getJobs(queueId, region, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the task executions in the specified job ID that have been updated since the last call.
     * @summary Get the updated task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskExecutionUpdates(
      queueId: string,
      jobId: string,
      region?: string,
      after?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<TaskExecutionUpdatesResponse> {
      return localVarFp
        .getTaskExecutionUpdates(
          queueId,
          jobId,
          region,
          after,
          paginationToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the task executions in the specified job ID.
     * @summary Get the task executions in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskExecutions(
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<TaskExecutionsResponse> {
      return localVarFp
        .getTaskExecutions(
          queueId,
          jobId,
          region,
          filterName,
          paginationToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all tasks of the specified `jobId`. To retrieve a specific task by name, add a filter as a query param using the syntax filter[name]=<name to find>.
     * @summary Get the list of tasks in a job
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} jobId The job ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [filterName] Filter by name
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTasks(
      queueId: string,
      jobId: string,
      region?: string,
      filterName?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<TasksResponse> {
      return localVarFp
        .getTasks(queueId, jobId, region, filterName, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the jobs in the specified queue that have been updated since the last call. Use the special value @default for the queue ID to target the default queue.
     * @summary Get the updated jobs in a queue
     * @param {string} queueId The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
     * @param {string} [after] A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
     * @param {string} [before] A timestamp in ISO 8601 format used to indicate that only results before this time are interesting.
     * @param {string} [paginationToken] Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdatedJobs(
      queueId: string,
      region?: string,
      after?: string,
      before?: string,
      paginationToken?: string,
      options?: any,
    ): AxiosPromise<UpdatedJobsResponse> {
      return localVarFp
        .getUpdatedJobs(
          queueId,
          region,
          after,
          before,
          paginationToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelJob operation in JobsApi.
 * @export
 * @interface JobsApiCancelJobRequest
 */
export interface JobsApiCancelJobRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiCancelJob
   */
  readonly queueId: string;

  /**
   *
   * @type {CancelJobRequest}
   * @memberof JobsApiCancelJob
   */
  readonly cancelJobRequest: CancelJobRequest;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiCancelJob
   */
  readonly region?: string;
}

/**
 * Request parameters for createJob operation in JobsApi.
 * @export
 * @interface JobsApiCreateJobRequest
 */
export interface JobsApiCreateJobRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiCreateJob
   */
  readonly queueId: string;

  /**
   *
   * @type {CreateJobRequest}
   * @memberof JobsApiCreateJob
   */
  readonly createJobRequest: CreateJobRequest;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiCreateJob
   */
  readonly region?: string;
}

/**
 * Request parameters for deleteJob operation in JobsApi.
 * @export
 * @interface JobsApiDeleteJobRequest
 */
export interface JobsApiDeleteJobRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiDeleteJob
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiDeleteJob
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiDeleteJob
   */
  readonly region?: string;
}

/**
 * Request parameters for getJob operation in JobsApi.
 * @export
 * @interface JobsApiGetJobRequest
 */
export interface JobsApiGetJobRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetJob
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetJob
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetJob
   */
  readonly region?: string;
}

/**
 * Request parameters for getJobLogs operation in JobsApi.
 * @export
 * @interface JobsApiGetJobLogsRequest
 */
export interface JobsApiGetJobLogsRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetJobLogs
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetJobLogs
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetJobLogs
   */
  readonly region?: string;

  /**
   * An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
   * @type {string}
   * @memberof JobsApiGetJobLogs
   */
  readonly path?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetJobLogs
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getJobOutputs operation in JobsApi.
 * @export
 * @interface JobsApiGetJobOutputsRequest
 */
export interface JobsApiGetJobOutputsRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetJobOutputs
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetJobOutputs
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetJobOutputs
   */
  readonly region?: string;

  /**
   * An output path that identifies a resource produced by a job that can be retrieved from the resource storage api. For example \&#39;/simulation/1/\&#39; represents all outputs for executionIndex&#x3D;1 for the task named \&#39;simulation\&#39;.
   * @type {string}
   * @memberof JobsApiGetJobOutputs
   */
  readonly path?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetJobOutputs
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getJobs operation in JobsApi.
 * @export
 * @interface JobsApiGetJobsRequest
 */
export interface JobsApiGetJobsRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetJobs
   */
  readonly queueId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetJobs
   */
  readonly region?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetJobs
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getTaskExecutionUpdates operation in JobsApi.
 * @export
 * @interface JobsApiGetTaskExecutionUpdatesRequest
 */
export interface JobsApiGetTaskExecutionUpdatesRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetTaskExecutionUpdates
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetTaskExecutionUpdates
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetTaskExecutionUpdates
   */
  readonly region?: string;

  /**
   * A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
   * @type {string}
   * @memberof JobsApiGetTaskExecutionUpdates
   */
  readonly after?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetTaskExecutionUpdates
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getTaskExecutions operation in JobsApi.
 * @export
 * @interface JobsApiGetTaskExecutionsRequest
 */
export interface JobsApiGetTaskExecutionsRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetTaskExecutions
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetTaskExecutions
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetTaskExecutions
   */
  readonly region?: string;

  /**
   * Filter by name
   * @type {string}
   * @memberof JobsApiGetTaskExecutions
   */
  readonly filterName?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetTaskExecutions
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getTasks operation in JobsApi.
 * @export
 * @interface JobsApiGetTasksRequest
 */
export interface JobsApiGetTasksRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetTasks
   */
  readonly queueId: string;

  /**
   * The job ID
   * @type {string}
   * @memberof JobsApiGetTasks
   */
  readonly jobId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetTasks
   */
  readonly region?: string;

  /**
   * Filter by name
   * @type {string}
   * @memberof JobsApiGetTasks
   */
  readonly filterName?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetTasks
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for getUpdatedJobs operation in JobsApi.
 * @export
 * @interface JobsApiGetUpdatedJobsRequest
 */
export interface JobsApiGetUpdatedJobsRequest {
  /**
   * The queue ID. Use the special ID &#x60;@default&#x60; to indicate the default queue for the user or client app.
   * @type {string}
   * @memberof JobsApiGetUpdatedJobs
   */
  readonly queueId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted job queue was created or else the request will fail.
   * @type {string}
   * @memberof JobsApiGetUpdatedJobs
   */
  readonly region?: string;

  /**
   * A timestamp in ISO 8601 format used to indicate that only results after this time are interesting.
   * @type {string}
   * @memberof JobsApiGetUpdatedJobs
   */
  readonly after?: string;

  /**
   * A timestamp in ISO 8601 format used to indicate that only results before this time are interesting.
   * @type {string}
   * @memberof JobsApiGetUpdatedJobs
   */
  readonly before?: string;

  /**
   * Job pagination token. This parameter should not be specified manually, instead the complete &#x60;nextUrl&#x60; should be used to retrieve the next set of jobs. It is documented here for completeness.
   * @type {string}
   * @memberof JobsApiGetUpdatedJobs
   */
  readonly paginationToken?: string;
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   * Cancel the job specified by the `jobId`.
   * @summary Cancel a job
   * @param {JobsApiCancelJobRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public cancelJob(
    requestParameters: JobsApiCancelJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .cancelJob(
        requestParameters.queueId,
        requestParameters.cancelJobRequest,
        requestParameters.region,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new job in the queue specified by `queueId`. The description of the job specified in the request body consists of a combination of the generic structure given here and executor-specific parts. In particular the `payload` property contains executor specific data. For the specifics there refer to the executor schema definition. This endpoint expects an OAuth2 bearer token with code:all scope.
   * @summary Create a new job
   * @param {JobsApiCreateJobRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public createJob(
    requestParameters: JobsApiCreateJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .createJob(
        requestParameters.queueId,
        requestParameters.createJobRequest,
        requestParameters.region,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete the specified job. Job related data is expected to be physically deleted within 30 days of its creation.
   * @summary Delete a job
   * @param {JobsApiDeleteJobRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public deleteJob(
    requestParameters: JobsApiDeleteJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .deleteJob(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the job specified by the `jobId`.
   * @summary Get a job
   * @param {JobsApiGetJobRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJob(
    requestParameters: JobsApiGetJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJob(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of logs from a job with the specified job ID.  A signed URL to download a log can be retrieved by using the information provided in the response and the resource API.
   * @summary Get the list of logs from a job
   * @param {JobsApiGetJobLogsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobLogs(
    requestParameters: JobsApiGetJobLogsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJobLogs(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        requestParameters.path,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of outputs of the job with the specified `jobId`. A signed URL to download the data of an output can be retrieved by using the information provided in the response and the resource API.
   * @summary Get a list of the job\'s outputs
   * @param {JobsApiGetJobOutputsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobOutputs(
    requestParameters: JobsApiGetJobOutputsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJobOutputs(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        requestParameters.path,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the jobs in a queue. By default retrieves at most 100 jobs per request. Further jobs can be retrieved by calling the `nextUrl` given in the `pagination` property of the response. If no `nextUrl` is present, that means that no more jobs can be retrieved.  **Note:** There may be fewer than 100 jobs retrieved per request, even though there may be more jobs available.
   * @summary Get a list of jobs
   * @param {JobsApiGetJobsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobs(
    requestParameters: JobsApiGetJobsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJobs(
        requestParameters.queueId,
        requestParameters.region,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the task executions in the specified job ID that have been updated since the last call.
   * @summary Get the updated task executions in a job
   * @param {JobsApiGetTaskExecutionUpdatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getTaskExecutionUpdates(
    requestParameters: JobsApiGetTaskExecutionUpdatesRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getTaskExecutionUpdates(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        requestParameters.after,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the task executions in the specified job ID.
   * @summary Get the task executions in a job
   * @param {JobsApiGetTaskExecutionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getTaskExecutions(
    requestParameters: JobsApiGetTaskExecutionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getTaskExecutions(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        requestParameters.filterName,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all tasks of the specified `jobId`. To retrieve a specific task by name, add a filter as a query param using the syntax filter[name]=<name to find>.
   * @summary Get the list of tasks in a job
   * @param {JobsApiGetTasksRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getTasks(
    requestParameters: JobsApiGetTasksRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getTasks(
        requestParameters.queueId,
        requestParameters.jobId,
        requestParameters.region,
        requestParameters.filterName,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the jobs in the specified queue that have been updated since the last call. Use the special value @default for the queue ID to target the default queue.
   * @summary Get the updated jobs in a queue
   * @param {JobsApiGetUpdatedJobsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getUpdatedJobs(
    requestParameters: JobsApiGetUpdatedJobsRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getUpdatedJobs(
        requestParameters.queueId,
        requestParameters.region,
        requestParameters.after,
        requestParameters.before,
        requestParameters.paginationToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
