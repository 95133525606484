/* tslint:disable */
/* eslint-disable */
/**
 * Bifrost job specialization
 * Defines the Bifrost-specific parts of a job description in a job submitted to the Job API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Execution } from './execution';
import { Options } from './options';
import { Ports } from './ports';
import { TaskInput } from './task-input';

/**
 * Describes a job operating on compounds
 * @export
 * @interface CompoundJob
 */
export interface CompoundJob {
  /**
   * Specifies the overall action that Bifrost should perform for this job.
   * @type {string}
   * @memberof CompoundJob
   */
  action: CompoundJobActionEnum;
  /**
   *
   * @type {Options}
   * @memberof CompoundJob
   */
  options: Options;
  /**
   * The list of Bifrost definition files to load
   * @type {Array<TaskInput>}
   * @memberof CompoundJob
   */
  definitionFiles?: Array<TaskInput>;
  /**
   *
   * @type {Ports}
   * @memberof CompoundJob
   */
  ports?: Ports;
  /**
   * List of executions to perform.
   * @type {Array<Execution>}
   * @memberof CompoundJob
   */
  executions?: Array<Execution>;
}

/**
 * @export
 * @enum {string}
 */
export enum CompoundJobActionEnum {
  Evaluate = 'Evaluate',
  ListPorts = 'ListPorts',
}
