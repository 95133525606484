/* tslint:disable */
/* eslint-disable */
/**
 * Job API
 * API schema for the Job API. This API allows for submitting, monitoring and controlling jobs running in the service.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies the status of a task/job
 * @export
 * @enum {string}
 */

export enum TaskStatus {
  /**
   * Initial state
   */
  Created = 'CREATED',
  /**
   * Waiting for dependencies or compute resources
   */
  Waiting = 'WAITING',
  /**
   * Scheduled to run
   */
  Scheduled = 'SCHEDULED',
  /**
   * In progress
   */
  Running = 'RUNNING',
  /**
   * Completed successfully
   */
  Succeeded = 'SUCCEEDED',
  /**
   * Failed to run or ended with an error
   */
  Failed = 'FAILED',
  /**
   * Canceled
   */
  Canceled = 'CANCELED',
}
