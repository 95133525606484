import Button, { ButtonProps } from '@mui/material/Button';

export const ActionButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'capitalize',
        fontSize: '13px',
        color: 'white',
        ...props.sx,
      }}
    >
      <>{props.children}</>
    </Button>
  );
};
